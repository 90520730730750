
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        




















































































.mentor-filter {
  display: flex;
  @media (max-width: $screen-width-sm) {
    flex-direction: column;
  }
}

.multiselect {
  margin: $text-xs;
}
