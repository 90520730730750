@import url(https://use.typekit.net/ejy7pnr.css);
@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.mentor-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 576px) {
.mentor-filter {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
}
}
.multiselect {
  margin: 14px;
}


/*# sourceMappingURL=blog~mentor.484756b3.css.map*/